<script lang="ts" setup>
import useExternalModalState from '~/composables/useExternalModalState';

const TEST_ID = 'confirmation-modal';

const { t } = useI18n();
const modalStore = useModalStore();

const isLoading = ref(false);

const computedVisible = computed(() => modalStore.confirmationModal.visible);

const [modalVisible, onToggleVisible] = useExternalModalState(
  computedVisible,
  () => {
    if (!isLoading.value) modalStore.closeConfirmationModal();
  },
);

const accept = async () => {
  const action = modalStore.confirmationModal.accept?.();
  if (action instanceof Promise) {
    isLoading.value = true;
    action.then(modalStore.acceptConfirmationModal).finally(() => {
      isLoading.value = false;
    });
  } else modalStore.acceptConfirmationModal();
};

const decline = () => {
  modalStore.confirmationModal.decline?.();
  modalStore.declineConfirmationModal();
};
</script>

<template>
  <BaseModal
    :visible="modalVisible"
    @update:visible="onToggleVisible"
    :aria-title="t('title')"
    :aria-describedby="modalStore.confirmationModal.title"
    container-class="baseConfirmationModal"
    :z-index="101"
    type="alert"
    :data-test-id="TEST_ID"
  >
    <p class="baseConfirmationModal__message">
      {{ modalStore.confirmationModal.title }}
    </p>
    <div class="baseConfirmationModal__footer">
      <AlertDialogCancel as-child>
        <BaseButton
          @click="decline"
          :disabled="isLoading"
          dark
          outlined
          size="s"
          class="baseConfirmationModal__action"
          :data-test-id="`${TEST_ID}-reject`"
        >
          {{ t('decline') }}
        </BaseButton>
      </AlertDialogCancel>
      <AlertDialogAction as-child>
        <BaseButton
          @click="accept"
          :loading="isLoading"
          dark
          size="s"
          class="baseConfirmationModal__action"
          :data-test-id="`${TEST_ID}-accept`"
        >
          {{ t('accept') }}
        </BaseButton>
      </AlertDialogAction>
    </div>
  </BaseModal>
</template>

<i18n>
ru:
  title: Подтвердите действие
  accept: Да
  decline: Нет

en:
  title: Confirm action
  accept: Yes
  decline: No
</i18n>

<style lang="scss">
.baseConfirmationModal {
  max-width: 532px;
  width: 100%;
  padding: 76px 64px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq('sm') {
    padding: 102px 16px 48px;
  }

  &__message {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 36px;

    @include mq('sm') {
      margin-bottom: 64px;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }

  &__action {
    width: 155px;

    @include mq('sm') {
      width: 100%;
    }
  }
}
</style>
